import Table from 'components/elements/Table/Table';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import './StageComponent.scss';
import Moment from 'moment';
import DetailButton from 'components/elements/DetailButton/DetailButton';
import NoInfo from 'components/elements/NoInfo/NoInfo';
import { sortByDecreasingDate } from 'components/Utils/sortDate';
import Checked from 'components/Utils/CheckedComponent/checkedComponent';

const GeneralEntranceComponent = (props) => {
    const { history, user, bookings } = props;
    const [data, setData] = useState();
    const [loadingData, setLoadingData] = useState(false);

    const columns = [
        {
            title: 'Día de la compra'.toUpperCase(),
            dataIndex: 'buyDate',
            key: 'buyDate',
            width: 150,
        },
        {
            title: 'Día de la reserva'.toUpperCase(),
            dataIndex: 'eventDate',
            key: 'eventDate',
            width: 150,
        },
        {
            title: 'Evento'.toUpperCase(),
            dataIndex: 'event',
            key: 'event',
            width: 150,
        },
        {
            title: 'N°reserva'.toUpperCase(),
            dataIndex: 'reservationNumber',
            key: 'reservationNumber',
            width: 200,
        },
        {
            title: 'Nombre'.toUpperCase(),
            dataIndex: 'name',
            key: 'name',
            width: 405,
        },
        {
            title: 'Reservado'.toUpperCase(),
            dataIndex: 'stage',
            key: 'stage',
            width: 80,
        },
        {
            title: 'N°Personas'.toUpperCase(),
            dataIndex: 'peopleNumber',
            key: 'peopleNumber',
            width: 100,
        },
        {
            title: 'importe'.toUpperCase(),
            dataIndex: 'price',
            key: 'price',
            width: 100,
        },
        {
            title: 'Acción'.toUpperCase(),
            dataIndex: 'action',
            key: 'action',
        },
    ]

    useEffect(() => {
        if (bookings) {
            const dataAux = bookings.map((booking) => ({
                buyDate: Moment(booking.createdAt).format('LLL'),
                eventDate: booking?.bookingDate ? Moment(booking?.bookingDate).format('DD/MM/YY') : '-',
                event: booking?.eventInfo?.headline || '-',
                reservationNumber: booking?.bookingNumber || '-',
                name: booking?.ownerInfo?.name || '-',
                stage: booking?.tickets[0]?.palcoNumber || '-',
                peopleNumber: booking?.tickets?.length || '0',
                price: `${booking?.totalPrice}€` || '-',
                action: <DetailButton onClick={() => history.push(`/club/${user.id}/booking/stage/details/${booking?.bookingId}`)}>Ver Detalles</DetailButton>,
                isActive: !!booking?.eventInfo && !!booking.ownerInfo

            })
            ).filter((booking) => booking.isActive)
            const sortedByDate = sortByDecreasingDate(dataAux, 'buyDate', 'DD/MM/YY')

            if (sortedByDate.length === 0) setData(null)
            else setData([...sortedByDate]);

            setLoadingData(false);
        }
    }, [bookings])

    return (
        <DashboardLayout
            title={<h3>Reservas</h3>}
            subtitle={<h4>Reservado</h4>}
        >
            <div className="stage-wrapper">
                {loadingData || data?.length > 0 ? <Table columns={columns} data={data} /> : <NoInfo />}
            </div>
        </DashboardLayout>
    );
}

export default GeneralEntranceComponent;